import request from '@/utils/request'

// 首页echarts数据
export function indexEtax(data) {
  return request({
    url: 'api/v2/home/indexEtax',
    method: 'post',
    data
  })
}

export function indexEtax2(data) {
  return request({
    url: 'api/v2/home/indexEtax2',
    method: 'post',
    data
  })
}

export function indexTax(data) {
  return request({
    url: 'api/v2/home/indexTax',
    method: 'post',
    data
  })
}

export function getOutStatistics(data) {
  return request({
    url: 'api/v2/home/getOutStatistics',
    method: 'post',
    data
  })
}

export function outStatisticsSave(data) {
  return request({
    url: 'api/v2/home/outStatisticsSave',
    method: 'post',
    data
  })
}