<template>
  <div id="daoPiao" style="width:30%;height:100%;display:inline-block;position: relative;top: 10px;"></div>
  <div style="display:inline-block;height:100%;width:70%;position: relative;bottom: 30px">
    <div class="totalStyle">到票统计</div>

    <div style="position:relative;top:61px;left:15px;line-height:22px;margin-right: 10px;">
      <ul style="font-size:10px">
        <li>未 到 票</li>
        <li>部分到票</li>
        <li>全部到票</li>
      </ul>
    </div>
    <div style="width:90%;padding-left: 70px;">
      <el-progress :text-inside="true" :stroke-width="12" :percentage="wdpPer" style="margin-bottom:10px" status="warning"></el-progress>
      <el-progress :text-inside="true" :stroke-width="12" :percentage="bfdpPer" status="info" style="margin-bottom:10px"></el-progress>
      <el-progress :text-inside="true" :stroke-width="12" :percentage="ydpPer" status="success"></el-progress>
    </div>
  </div>
</template>

<script type="text/javascript">
import * as echarts from 'echarts';
export default {
  name:'daoPiaoTotal',
  props:{

  },
  data() {
    return {
      wdpPer:0,
      bfdpPer:0,
      ydpPer:0
    }
  },
  created(){
  },
  methods:{
    init(list){
      console.log(list);
      this.$nextTick(() => {
        this.initChart(list)
      })
    },
    initChart(list){
      let wdp = list.wdp
      let bfdp = list.bfdp
      let ydp = list.ydp
      let allTotal = wdp + bfdp + ydp
      if(wdp == 0){
        this.wdpPer = 0
      }else{
        this.wdpPer = (wdp/allTotal *100).toFixed(0)
      }
      if(bfdp == 0){
        this.bfdpPer = 0
      }else{
        this.bfdpPer = (bfdp/allTotal *100).toFixed(0)
      }
      if(ydp == 0){
        this.ydpPer = 0
      }else{
        this.ydpPer = (ydp/allTotal *100).toFixed(0) 
      }

      var myChart = echarts.init(document.getElementById('daoPiao'));
      myChart.setOption({
        title: {
          // text: '到票统计',
          left: 'right',
          padding:10,
          textStyle:{
            fontSize: '15',
            fontWeight:'normal',
          }
        },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   // orient: 'vertical',
        //   bottom: 'left',
        //   paddingBottom:5,
        //   itemGap: 2,
        //   itemWidth: 5,
        //   itemHeight: 5
        // },
        color:['#e6a23c','#409eff','#67c23a'],
        series: [ 
          {
            type: 'pie',
            radius: ['80%', '55%'],
            label: {
              show: false,
              position: 'center',
              formatter: '{b}:{c}' + '\n\r' + '({d}%)',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '12',
              }
            },
            labelLine: {
              show: true
            },
            data: [
              { value: wdp , name:'未到票'},
              { value: bfdp , name:'部分到票'},
              { value: ydp , name:'全部到票'}
            ]
          }
        ]
      })
      window.onresize = function(){
        myChart.resize()
      }
    }
  }
}
</script>