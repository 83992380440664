<template>
  <div id="checkTotal" style="width:30%;height:100%;display:inline-block;position: relative;top: 10px;"></div>
  <div style="display:inline-block;height:100%;width:70%;position: relative;bottom: 30px">
    <div class="totalStyle">检查统计</div>

    <div style="position:relative;top:61px;left:15px;line-height:22px;margin-right: 10px;">
      <ul style="font-size:10px">
        <li>未开始</li>
        <li>进行中</li>
        <li>已完成</li>
      </ul>
    </div>
    <div style="width:90%;padding-left: 70px;">
      <el-progress :text-inside="true" :stroke-width="12" :percentage="wksPer" style="margin-bottom:10px" status="warning" ></el-progress>
      <el-progress :text-inside="true" :stroke-width="12" :percentage="jxzPer" status="info" style="margin-bottom:10px" ></el-progress>
      <el-progress :text-inside="true" :stroke-width="12" :percentage="ywcPer" status="success" ></el-progress>
    </div>
  </div>
</template>

<script type="text/javascript">
import * as echarts from 'echarts';
export default {
  name:'checkTotal',
  props:{

  },
  data() {
    return {
      wksPer:0,
      jxzPer:0,
      ywcPer:0,
    }
  },
  created(){
  },
  methods:{
    init(list){
      console.log(list);
      this.$nextTick(() => {
        this.initChart(list)
      })
    },
    initChart(list){
      let wks = list.wks
      let jxz = list.jxz
      let ywc = list.ywc
      let allTotal = wks + jxz +ywc

      if(wks == 0){
        this.wksPer = 0
      }else{
        this.wksPer = (wks/allTotal *100).toFixed(0)
      }
      if(jxz == 0){
        this.jxzPer = 0
      }else{
        this.jxzPer = (jxz/allTotal *100).toFixed(0)
      }
      if(wks == 0){
        this.ywcPer = 0
      }else{
        this.ywcPer = (ywc/allTotal *100).toFixed(0) 
      }
      var myChart = echarts.init(document.getElementById('checkTotal'));
      myChart.setOption({
        // title: {
        //   text: '检查统计',
        //   left: 'center',
        //   padding:10,
        //   textStyle:{
        //     fontSize: '13',
        //     fontWeight:'normal'
        //   }
        // },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   // orient: 'vertical',
        //   bottom: 'left',
        //   paddingBottom:5,
        //   itemGap: 2,
        //   itemWidth: 5,
        //   itemHeight: 5
        // },
        color:['#e6a23c','#409eff','#67c23a'],

        series: [ 
          {
            type: 'pie',
            radius: ['80%', '55%'],
            label: {
              show: false,
              position: 'center',
              formatter: '{b}:{c}' + '\n\r' + '({d}%)',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '12',
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: wks , name:'未开始'},
              { value: jxz , name:'进行中'},
              { value: ywc , name:'已完成'}
            ]
          }
        ]
      })
      window.onresize = function(){
        myChart.resize()
      }
    }
  }
}
</script>