<template>
  <div style="width:100%;height:100%;padding:10px">
    <div style="width:100%;height:20%">
      <div style="margin-bottom:10px">
        <div style="width:100%;display:inline-block;position:relative">
          <span class="leftBlueBorder"></span>
          <span style="font-size:15px;font-weight:500;margin-right:40px;margin-left:10px">进度统计</span>
          <span style="font-size:14px;color:#e6a23c">你好{{cnName}},今天是我们相识的第<span style="font-size:16px;color:#fe696a">{{apartTime}}</span>天,本月报税期截止时间为<span style="font-size:16px;color:#fe696a">{{deadlineDay}}</span>号,继续加油！</span>
        </div>
      </div>
      <div style="width:100%;display:inline-block;height:100%">
        <div class="leftExample">
          <daoPiaoTotal ref="daoPiaoTotal"></daoPiaoTotal>
        </div>
        <div class="leftExample">
          <bookTotal ref="bookTotal"></bookTotal>
        </div>
        <div class="leftExample">
          <shenBaoTotal ref="shenBaoTotal"></shenBaoTotal>
        </div>
        <div class="leftExample">
          <chargebackTotal ref="chargebackTotal"></chargebackTotal>
        </div>
        <div class="leftExample2">
          <checkTotal ref="checkTotal"></checkTotal>
          <div style="position:absolute;right:0px;top:-34px">
            <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
          </div>
        </div>
      </div>


      <!-- <div class="rightExample">
        <img src="@/assets/jiaocheng.jpg" alt="" srcset="" style="display: inline-block;width:100%;height:100%">
      </div> -->
    </div>
    <div>
      <div style="width:100%;display:inline-block;position:relative;margin-top: 10px;" v-if="this.isAdmin == 1">
        <span class="leftBlueBorder"></span>
        <span style="font-size:15px;font-weight:500;margin-right:40px;margin-left:10px">客户类型统计</span>
        <span style="font-size:14px;margin-right:10px">当前可使用账套数 <span style="color:#3c6ff2">{{ this.count - this.xgmCount-this.ybCount }}</span> 户</span>
        <span style="font-size:14px;margin-right:10px">总账套数<span style="color:#3c6ff2"> {{ this.count }}</span>  户</span>
        <span style="font-size:14px;margin-right:10px;">记账客户总数<span style="color:#3c6ff2"> {{ this.xgmCount+this.ybCount }}</span>  户</span>
        <span style="font-size:14px;margin-right:10px">一般纳税人<span style="color:#ff7a00">{{ this.ybCount }}</span> 户 占比<span style="color:#ff7a00">{{ ((this.ybCount/(this.xgmCount+this.ybCount))*100).toFixed(2) }}%</span></span>   
        <span style="font-size:14px;margin-right:10px">小规模纳税人<span style="color:#3c6ff2">{{ this.xgmCount }}</span> 户    占比<span style="color:#3c6ff2">{{ ((this.xgmCount/(this.xgmCount+this.ybCount))*100).toFixed(2) }}%</span></span>
        <span style="font-size:14px;">到期时间: <span style="color:#3c6ff2">{{ this.endTime }}</span> </span>
      </div>
    </div>
    <div style="width:100%;height:65%;margin-top:12px">
      <div style="margin-bottom:10px">
        <el-input size="small" style="width:150px" v-model="listQuery.name" @keyup.enter="getList"></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:11px 0px 0px 15px;width:400px" class="styleForm">
            <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">   
              <selecttaxtype v-model:type="listQuery.type" @success="getList()"></selecttaxtype>
            </el-form-item>
            <el-form-item label="到票状态：">
              <el-radio v-model="listQuery.piaoStatus" :label="9" size="small" >未到票</el-radio>
              <el-radio v-model="listQuery.piaoStatus" :label="1" size="small" >部分到票</el-radio>
              <el-radio v-model="listQuery.piaoStatus" :label="2" size="small" >全部到票</el-radio>
            </el-form-item>
            <el-form-item label="记账状态：">   
              <el-radio v-model="listQuery.taxStatus" :label="9" size="small" >未开始</el-radio>
              <el-radio v-model="listQuery.taxStatus" :label="1" size="small" >进行中</el-radio>
              <el-radio v-model="listQuery.taxStatus" :label="2" size="small" >已完成</el-radio>
            </el-form-item>
            <el-form-item label="申报状态：">   
              <el-radio v-model="listQuery.declaredStatus" :label="9" size="small" >未申报</el-radio>
              <el-radio v-model="listQuery.declaredStatus" :label="1" size="small" >进行中</el-radio>
              <el-radio v-model="listQuery.declaredStatus" :label="2" size="small" >已完成</el-radio>
            </el-form-item>
            <el-form-item label="扣款状态：">   
              <el-radio v-model="listQuery.deductionStatus" :label="9" size="small" >未扣款</el-radio>
              <el-radio v-model="listQuery.deductionStatus" :label="1" size="small" >进行中</el-radio>
              <el-radio v-model="listQuery.deductionStatus" :label="2" size="small" >已完成</el-radio>
            </el-form-item>
            <el-form-item label="检查状态：">   
              <el-radio v-model="listQuery.checkStatus" :label="9" size="small" >未检查</el-radio>
              <el-radio v-model="listQuery.checkStatus" :label="1" size="small" >进行中</el-radio>
              <el-radio v-model="listQuery.checkStatus" :label="2" size="small" >已完成</el-radio>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId"></selectuser>
            </el-form-item>
          </el-form>
        </search>
        <!-- <el-button style="float:right" size="small">导出</el-button> -->
      </div>
      <el-table :data="list" :height="contentStyleObj" border>
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="index" label="编号" width="55" />
        <el-table-column prop="name" label="公司名称" min-width="240" >
          <template #default="scope">
            <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
            <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
            <span @click="$goEtax(scope.row.id)" style="cursor:pointer">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="到票状态" min-width="130" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.piaoStatus == 9">
              <i class="iconfont icon-gantanhao"></i>
              <p>未到票</p>
            </div>
            <div class="item_icon" v-else-if="scope.row.piaoStatus == 1">
              <i class="iconfont icon-info"></i>
              <p>部分到票</p>
            </div>
            <div class="item_icon" v-if="scope.row.piaoStatus == 2">
              <i class="iconfont icon-duihao"></i>
              <p>全部到票</p>
            </div>
            <i class="iconfont icon-bianji" @click="ticketOpen(scope.row)" style="cursor:pointer"></i>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="记账状态" min-width="130" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.taxStatus == 9">
              <i class="iconfont icon-gantanhao"></i>
              <p>未记账</p>
            </div>
            <div class="item_icon" v-else-if="scope.row.taxStatus == 1">
              <i class="iconfont icon-info"></i>
              <p>进行中</p>
            </div>
            <div class="item_icon" v-if="scope.row.taxStatus == 2">
              <i class="iconfont icon-duihao"></i>
              <p>已完成</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="申报状态" min-width="130" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.declaredStatus == 9">
              <i class="iconfont icon-gantanhao"></i>
              <p>未申报</p>
            </div>
            <div class="item_icon" v-else-if="scope.row.declaredStatus == 1">
              <i class="iconfont icon-info"></i>
              <p>进行中</p>
            </div>
            <div class="item_icon" v-if="scope.row.declaredStatus == 2">
              <i class="iconfont icon-duihao"></i>
              <p>已完成</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="扣款状态" min-width="130" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.deductionStatus == 9">
              <i class="iconfont icon-gantanhao"></i>
              <p>未扣款</p>
            </div>
            <div class="item_icon" v-else-if="scope.row.deductionStatus == 1">
              <i class="iconfont icon-info"></i>
              <p>进行中</p>
            </div>
            <div class="item_icon" v-if="scope.row.deductionStatus == 2">
              <i class="iconfont icon-duihao"></i>
              <p>已完成</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="检查状态" min-width="130" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.checkStatus == 9">
              <i class="iconfont icon-gantanhao"></i>
              <p>未检查</p>
            </div>
            <div class="item_icon" v-else-if="scope.row.checkStatus == 1">
              <i class="iconfont icon-info"></i>
              <p>进行中</p>
            </div>
            <div class="item_icon" v-if="scope.row.checkStatus == 2">
              <i class="iconfont icon-duihao"></i>
              <p>已完成</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="随手记" min-width="180" >
          <template #default="scope">
            <el-popover v-model:visible="scope.row.visible" placement="bottom" trigger="click"  :width="350"  @after-leave="editNote1(scope.row)">
              <el-input
                v-model="noteContent"
                :rows="4"
                type="textarea"
                placeholder="请输入"
              />
              <div style="text-align: right; margin-top: 10px">
                <el-button size="small" type="primary" @click="scope.row.visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="editNote(scope.row,2)">确定</el-button>
              </div>
              <template #reference>
                <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row.id)">记</span>
              </template>
            </el-popover>
            <span>{{scope.row.content}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="220" align="center">
          <template #default="scope">
            <el-button @click="$goEtax(scope.row.id)" size="small" type="primary" plain>
              <el-icon><House /></el-icon><span  > 进入账簿</span>
            </el-button>
            <!-- <el-button size="small" plain type="warning" @click="InTax(scope.row.id)"  v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="打开中请稍后...">
              <el-icon><HomeFilled /></el-icon><span  > 进入税局</span>
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
      <ticket-status ref="ticketStatus" @success="getList"></ticket-status>
  </div>
</template>

<script>
import { currentAccountPeriod } from '@/utils'
import { indexEtax2 } from "@/api/home"
import axios from 'axios'

import { comMemoryInfo , comMemorySave,loginTaxInfo} from "@/api/company"
import selecttaxtype from "@/components/Screening/selecttaxtype";
import daoPiaoTotal from './components/daoPiaoTotal.vue'
import bookTotal from './components/bookTotal.vue'
import shenBaoTotal from './components/shenBaoTotal.vue'
import chargebackTotal from './components/chargebackTotal.vue'
import checkTotal from "./components/checkTotal"

export default {
  name:'homeIndex2',
  components:{ daoPiaoTotal, bookTotal , shenBaoTotal ,chargebackTotal , checkTotal,selecttaxtype },

  data(){
     return {
      list:[],
      listQuery:{
        period:currentAccountPeriod(),
        name:'',
        limit:20,
        page:1,
      },
      total:0,
      visible:false,
      cnName:this.$store.getters['user/user'].cnName,
      deadlineDay:'',
      apartTime:'',
      count:0,
      xgmCount:0,
      ybCount:0,
      visible:false,
      noteContent:'',
      isAdmin:this.$store.getters['user/user'].isAdmin,
      dialogConfirm:false,
      fullscreenLoading:false,
      endTime:''

    }
  },
  created(){
    console.log(window.name)
    this.getList()
    this.contentStyleObj= this.$getHeight(480)
  },
  methods:{
    getList(){
      indexEtax2(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
        this.apartTime = res.data.data.apartTime
        this.deadlineDay = res.data.data.day
        this.$refs.daoPiaoTotal.init(res.data.data.dpzt)
        this.$refs.bookTotal.init(res.data.data.jzzt)
        this.$refs.shenBaoTotal.init(res.data.data.sbzt)
        this.$refs.chargebackTotal.init(res.data.data.kkzt)
        this.$refs.checkTotal.init(res.data.data.jczt)
        this.count = res.data.data.count
        this.xgmCount = res.data.data.xgmCount
        this.ybCount = res.data.data.ybCount
        let endTime = res.data.data.endTime.replace('-','年')
        this.endTime = endTime.replace('-','月').concat('日')
      })
    },
    smallNotes(id){
      this.visible = true
      this.dialogConfirm = false
      comMemoryInfo({comId:id}).then(res=>{
        this.noteContent = res.data.data.content
      })
    },
    editNote(row,e){
      if(e == 2){
        this.dialogConfirm = true
      }
      comMemorySave({comId:row.id,content:this.noteContent}).then(res=>{
        row.content = this.noteContent
        if(res.data.msg == "success"){
          row.visible = false
          this.$qzfMessage("保存成功")
        }
      })
    },
    editNote1(row){
      if(this.dialogConfirm){
        return
      }
      comMemorySave({comId:row.id,content:this.noteContent}).then(res=>{
        row.content = this.noteContent
        if(res.data.msg == "success"){
          row.visible = false
          this.$qzfMessage("保存成功")
        }
      })
    },
    ticketOpen(row){
      let params = {
        comId:row.id,
        period:this.listQuery.period
      }
      this.$refs.ticketStatus.openDialog()
      this.$refs.ticketStatus.ticketStatus(params)
    },
    InTax(id){
      // this.$qzfMessage('打开中请稍后...',1);
      // this.fullscreenLoading = true、
      this.$alert('正在登录税局，预计1-2分钟', '进入税局提示', {
        confirmButtonText: '确定',
      });
      let that = this
      loginTaxInfo({id:id}).then(res=>{
        let param = res.data.data.info
        axios.post('http://localhost:9111/loginTax/autoLoginTax',param).then(res=>{
          // that.fullscreenLoading = false
          if(res.data.msg != "success"){
            that.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            });
          } 

        }).catch(function (e) {
          that.$alert('请先安装客户端', '提示', {
            confirmButtonText: '确定',
          });
          // that.fullscreenLoading = false
          return
        });

      })
    }
  }
}
</script>

<style lang="scss" scoped>
.leftExample{
  width:19%;
  display:inline-block;
  height: 80%;
  margin-right:1%;
  border:1px solid #eee;
  position: relative;
  // box-shadow:1px 1px 2px 0px rgba(0,0,0,0.2),0 1px 2px 0 rgba(0,0,0,0.2)

}
.leftExample2{
  width:20%;
  display:inline-block;
  height: 80%;
  // margin-right:1%;
  border:1px solid #eee;
  position: relative;
  // box-shadow:1px 1px 2px 0px rgba(0,0,0,0.2),0 1px 2px 0 rgba(0,0,0,0.2)

}
.item_icon{
  display: inline-block;
  width: 81%;
  padding: 1px 3px;
  padding-right: 10px;
  
  // cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }
  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
.leftBlueBorder{
  width:4px;
  height: 12px;
  background-color: $color-main;
  display: inline-block;
}
</style>